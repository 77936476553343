import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/System/Date_Time/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemPerm';
import DateTable from 'components/Web_User_Interface/1440p_Series/System/Date_Time/dateTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "System Menu // Date & Time",
  "path": "/Web_User_Interface/1440p_Series/System/Date_Time/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Your camera will automatically contact a so-called NTP Server to set the internal clock. It is recommended to leave this feature on at all times. But if your camera cannot connect to the internet, you can synchronize the clock with your computer. But be aware that this has to be done regularly to keep your camera on-time.",
  "image": "./WebUI_1440p_SearchThumb_System_Date_Time.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_System_Date_Time.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='System Menu // Date & Time' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Your camera will automatically contact a so-called NTP Server to set the internal clock. It is recommended to leave this feature on at all times. But if your camera cannot connect to the internet, you can synchronize the clock with your computer. But be aware that this has to be done regularly to keep your camera on-time.' image='/images/Search/WebUI_1440p_SearchThumb_System_Date_Time.png' twitter='/images/Search/WebUI_1440p_SearchThumb_System_Date_Time.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/System/Datum_Uhrzeit/' locationFR='/fr/Web_User_Interface/1440p_Series/System/Date_Time/' crumbLabel="Date & Time" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "system-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#system-menu",
        "aria-label": "system menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`System Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bbf89f3a7074f115fabee8f70544b867/b97f6/1440p_Settings_System_Date_Time.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7rAAAO6wFxzYGVAAACPklEQVQoz22SS2/TQBRG+y9AFDVtebR2EhGatBA1dd4kfqT2eOzYSZw+kRCbSrBjVWg3oBaQ+AcIxKbLthL8uoPs1H3B4mgWo3vmu3fuVC6XQ1UU8k+WaL78ROv1N7Tdz6xuH1HZOf4v2u4xrVdfEXtfqFTWuHv3DtPT08zOzjIVCxVlkXyhRPj+hPHH3wSHZ/QPzwkOz6+dZ/gHZ/gfTgkOTtk5+sN4/xflcoVHDx+TVXMsLCwypaoqMdlsFtvS8V0bX6zji16Ca5s4PR3P6RFIm9AXhJ5D4AvWTZ3Kap2q1qRWbVMsrjAVi2JhnNR1JcPhiH4Q4Hk+UnpIz8eVkn4QMhpFROMNovGYza2t5L5Ra1HVJtLi0vKVMD4dx8H3fYRwkVLiOALTsrAsC9M0MWIMI0HXDYRjYxkN6rXGTaGiKJfCIAhwXTfBtu2kMJVcodPpdJCyR+DVqa7VEmEpbvn6DFNhGIZ4npdI46S3cV1BP4wIBiHrukZNq9Fu1FkuXU+oqpfCuO1UKIRISFPHc5auwAm3GIzGSL1GvaolwmKxdDNhXJimS0WpLH4smaNhYuhdunafjUHE26FFs16n1WhTKj2b7GH6MXHxYDBIEt5OZ5k6Va2CVtVYKZdZKT/HMrrIVpni0wKFwhKqkr25NvEnTGY0aU9cSF3p4QYRTj/CEx7DXo8o3oCuzr37M8zMTMhkMlct5/P5RPjP3BwbMdjGf/cdsX/C3vgNP5ttfnRtNl90yDyYZ35ujrkL/gK1i8AhjetP1AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bbf89f3a7074f115fabee8f70544b867/e4706/1440p_Settings_System_Date_Time.avif 230w", "/en/static/bbf89f3a7074f115fabee8f70544b867/d1af7/1440p_Settings_System_Date_Time.avif 460w", "/en/static/bbf89f3a7074f115fabee8f70544b867/7f308/1440p_Settings_System_Date_Time.avif 920w", "/en/static/bbf89f3a7074f115fabee8f70544b867/44a65/1440p_Settings_System_Date_Time.avif 958w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bbf89f3a7074f115fabee8f70544b867/a0b58/1440p_Settings_System_Date_Time.webp 230w", "/en/static/bbf89f3a7074f115fabee8f70544b867/bc10c/1440p_Settings_System_Date_Time.webp 460w", "/en/static/bbf89f3a7074f115fabee8f70544b867/966d8/1440p_Settings_System_Date_Time.webp 920w", "/en/static/bbf89f3a7074f115fabee8f70544b867/cb8de/1440p_Settings_System_Date_Time.webp 958w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bbf89f3a7074f115fabee8f70544b867/81c8e/1440p_Settings_System_Date_Time.png 230w", "/en/static/bbf89f3a7074f115fabee8f70544b867/08a84/1440p_Settings_System_Date_Time.png 460w", "/en/static/bbf89f3a7074f115fabee8f70544b867/c0255/1440p_Settings_System_Date_Time.png 920w", "/en/static/bbf89f3a7074f115fabee8f70544b867/b97f6/1440p_Settings_System_Date_Time.png 958w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bbf89f3a7074f115fabee8f70544b867/c0255/1440p_Settings_System_Date_Time.png",
              "alt": "Web User Interface - 1440p Series - System Date & Time",
              "title": "Web User Interface - 1440p Series - System Date & Time",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <DateTable mdxType="DateTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`You can set up the internal clock of the camera to be either synchronized with your PC clock or with one of several NTP servers. As long as your camera has access to the internet it is recommended to use an NTP server synchronization to achieve a higher accuracy for alarm trigger events.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      